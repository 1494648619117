import { annotations } from './annotations.js';
import gsap from 'gsap';
import { animations } from './animations.js';
let selectable;
let selectedObject;
let scene;

export function initSelectObject(){
    scene = statesManager.states.villageScene.value;

    statesManager.states.hovered3D.callbacks.push( highlightHoveredObject );
    statesManager.setState('selectedObject', undefined, false, [setSelectedObject]);
    statesManager.states.clicked3D.callbacks.push( (changes) => statesManager.setState('selectedObject', changes.newValue[0].object));
    statesManager.states.reachedLocation.callbacks.push( () => statesManager.setState('selectedObject', undefined));
}

export function setSelectable(objNames){
    selectable = objNames
}

function highlightHoveredObject(changes) {
    if (!selectable) return;

    let hoveredObject;
    if (changes.newValue.length > 0) hoveredObject = changes.newValue[0].object;

    let prevHoveredObject;
    if (changes.prevValue.length > 0) prevHoveredObject = changes.prevValue[0].object;

    if (
    prevHoveredObject && 
    prevHoveredObject !== hoveredObject &&
    prevHoveredObject !== selectedObject &&
    selectable.includes(prevHoveredObject.name)
    )
    {
        highlightObject(prevHoveredObject, false);
    }
    
    if (hoveredObject && selectable.includes(hoveredObject.name)) {
        highlightObject(hoveredObject, true);
    }
}

function setSelectedObject(changes) {
    if (!selectable) return;

    selectedObject = changes.newValue;
    if (selectedObject) annotations.setTextVisibility(selectedObject.name, true);
    selectable.map(deselect);
    return;
}

function highlightObject(obj, selected) {
    animations.highlightObj(obj, selected)
    annotations.setAnnVisibility(obj.name, selected);
}

function deselect(objName) {
    if (selectedObject && objName === selectedObject.name) return;

    const obj = scene.getObjectByName(objName);
    highlightObject(obj, false)
    annotations.setTextVisibility(objName, false);
}