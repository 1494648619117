import './states_manager.js'
import './app/init_3d.js';

import { getRootHref } from './insertFrame.js';
import { goToLocation } from './goToLocation.js';
import { startGame } from './startGame.js';
import { servObj } from './app/init_glob.js';

statesManager.setState('playerCurrentNode', [10, 10])

const testBtns = document.querySelectorAll('.btn-to-test');
const modals = document.querySelectorAll('.modal');
const modalLinks = document.querySelectorAll('.modal-links');
const startGameWrap = document.querySelector('.start-game-wrap');
const previewBlock = document.querySelector('.preview-block');
const workshopName = document.querySelector('.workshop-name');
let annotationBtns = document.querySelectorAll('.ann-btn');
let rootHref = getRootHref();

//_____
const hoverableMeshNames = [
    'carpentry_workshop',
    'weaving_leather_workshop',
    'hand_windmill',
    'forge',
    'military_camp',

    'forge_gorn',
    'forge_molot',
    'forge_kleshi',
    'forge_nakovalnya',
];
const handleCursorStyle = (changes) => { 
    document.body.style.cursor = changes.newValue.length > 0 && hoverableMeshNames.includes(changes.newValue[0].object.name) ? 'pointer' : '';
}
statesManager.states.hovered3D.callbacks.push(handleCursorStyle);

statesManager.states.reachedLocation.callbacks.push((changes) => goToLocation(changes.newValue));

//ПЕРЕПИСАТЬ ВМЕСТЕ с things.js
// const goToThingPage = (changes) => {
//     if(!servObj.activeLocation || servObj.activeLocation == 'village') return;
//     console.log('goToThingPage: ', changes);
    
//     let object = changes.newValue[0].object;
//     let name = object && object.name.split('_')[1];
//     if(name && !name.includes('fon')) location.href = `./things/${object.name}.html?loc=${servObj.activeLocation}`;
// }
// statesManager.states.clicked3D.callbacks.push(goToThingPage);

function goToThingPage(e){
    if(!servObj.activeLocation || servObj.activeLocation == 'village') return;
    let name = e.currentTarget && e.currentTarget.dataset.name;
    if(name) location.href = `./things/${name}.html?loc=${servObj.activeLocation}`;
}
annotationBtns.forEach(el=>el.addEventListener('pointerdown', goToThingPage));

window.addEventListener('load',(e)=>{
    servObj.winLoaded = true;
    if(servObj.sceneLoaded)  document.getElementById('global-loader').style.display = 'none';
    // window.addEventListener('load_scene',(e)=>{
    //     console.log('load_scene: ', e);
    //     document.getElementById('global-loader').style.display = 'none';
    // })
});

window.addEventListener('load_scene',(e)=>{
    servObj.sceneLoaded = true;
    if(servObj.winLoaded)  document.getElementById('global-loader').style.display = 'none';

    // window.addEventListener('load',(e)=>{
    //         document.getElementById('global-loader').style.display = 'none';
    // })
});

function hidePreloader(){ 
    let globalLoader = document.getElementById('global-loader');
    globalLoader && (globalLoader.style.display = 'none');
};
// statesManager.states.villageLoaded.callbacks.push(hidePreloader);
//_____

const url = new URL(window.location.href);
let hash = window.location.hash.replace('#', '');

// const servObj = window.servObj = {}
if(hash){
    servObj.activeLocation = hash;
    window.location.hash = '';
    servObj.gameIsStarted = true;
    statesManager.setState('reachedLocation', servObj.activeLocation);
}

// if(performance.getEntriesByType('navigation')[0].type === 'reload') {
//     // Получаем текущий URL
//     const currentUrl = window.location.href;
//     // Создаем новый URLSearchParams объект с текущими параметрами строки запроса
//     const urlParams = new URLSearchParams(window.location.search);
//     // Проверяем, есть ли параметры для удаления
//     if (urlParams.toString() !== '') {
//         // Удаляем все параметры из URLSearchParams
//         urlParams.forEach((value, key) => {
//             urlParams.delete(key);
//         });
//         // Формируем новый URL без параметров
//         const newUrl = `${currentUrl.split('?')[0]}`;
//         // Перенаправляем пользователя на новый URL без параметров
//         window.location.replace(newUrl);
//     }
// }

const backpackIcons = {
    0: './images/icon/backpack_items/shirt.svg',
    1: './images/icon/backpack_items/bow.svg',
    2: './images/icon/backpack_items/food.svg',
    3: './images/icon/backpack_items/pants.svg',
    4: './images/icon/backpack_items/shield.svg',
}

const testID = {
    weaving_leather_workshop: 1,
    carpentry_workshop: 2,
    hand_windmill: 3,
    forge: 4,
    military_camp: 5,
}


startGameWrap.addEventListener('pointerdown', startGame);

previewBlock.style.display = servObj.gameIsStarted ? 'none' : 'flex';

let testingBtn = document.querySelector('.testing');
if(testingBtn){
    testingBtn.style.display = servObj.gameIsStarted ? 'block' : 'none';
    testingBtn.addEventListener('pointerdown', function(e) {
        window.location.href = rootHref + 'testing/testing.html'
    })
}

let backpak = document.querySelector('.backpack-wrap');
backpak && (backpak.style.display = servObj.gameIsStarted ? 'block' : 'none');

let backpackItems = document.querySelectorAll('.backpack-item');
backpackItems.forEach(el=>el.classList.remove('not-empty'));
const testState = JSON.parse(localStorage.getItem('testState'));

testState && Object.values(testState).forEach(state => {
    let trueIndx = state.testID - 1; 
    if(state.isDone && backpackItems[trueIndx]) {
        backpackItems[trueIndx].classList.add('not-empty');
        backpackItems[trueIndx].style.backgroundImage = `url(${backpackIcons[trueIndx]})`;
    }
})

//вкл.выкл. звук
// soundIconWrap.addEventListener('pointerdown', () => {
//     servObj.soundOn = !servObj.soundOn
//     soundIcon.style.backgroundImage = servObj.soundOn ? `url("${soundON}")` : `url("${soundMute}")`
// })

//открытие модалок
modalLinks.forEach(modalLink => {
    modalLink.addEventListener('pointerdown', (e) => {
        const modalName = e.currentTarget.dataset.modalName
        if(modalName) openModal(modalName)
    })
})

function openModal(targetModalName) {
    modals.forEach((modal) => {
        if(modal.id === targetModalName) {
            modal.classList.add('show')
        }
    })
}

//закрытие модалок
document.querySelectorAll('.modal-close-btn').forEach((closeIcon) => {
    closeIcon.addEventListener('pointerdown', () => {
        closeModal()
    })
})

modals.forEach((modal) => {
    modal.addEventListener('pointerdown', (event) => {
        // console.log('Pointerdown на модалке');
        // event.stopPropagation()
        document.querySelectorAll('.modal-close-btn').forEach((closeIcon) => {
            if(event.target !== modal) {
                return
            }
            closeModal()
        })

    })
})

function closeModal() {
    modals.forEach((modal) => {
        modal.classList.remove('show')
    })
}

//закрыть модалку по кнопке Отмена
document.querySelectorAll('.cancel-btn').forEach(cancelBtn => {
    cancelBtn.addEventListener('pointerdown', () => {
        closeModal()
    })
})

function goToPreviewBlock() {
    previewBlock.style.display = 'flex'
    workshopName.style.display = 'none'
}

function goToCurrentTest() {
    servObj.testIsActive = true

    const linkToTest = document.querySelector('.current-testing-link');
    linkToTest && (linkToTest.href = `./testing/testing.html?loc=${servObj.activeLocation}`)
}



function goToThings(htmlName) {
    const hrefToObject = document.querySelector('.link-to-object').href
    hrefToObject.replace('object_1', htmlName)
    window.location.href = `${hrefToObject.replace('object_1', htmlName)}?l=${servObj.activeLocation}`
}
