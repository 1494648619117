import { servObj } from "./app/init_glob"

export function goToLocation(locName) {

    servObj.activeLocation = locName;

    let testing = document.querySelector('.testing');
    testing && (testing.style.display = servObj.gameIsStarted && servObj.activeLocation == 'village' ? 'block' : 'none');

    const locationName = {
        carpentry_workshop: 'Столярная мастерская',
        weaving_leather_workshop: 'Ткацкая и кожевенная мастерская',
        hand_windmill: 'Ручная мельница',
        forge: 'Кузница',
        military_village: 'Военный лагерь',
    }

    document.querySelector('.back-to-village-wrap').style.display = servObj.activeLocation !== 'village' && servObj.gameIsStarted ? 'block' : 'none';
    document.querySelector('.workshop-name').textContent = locationName[servObj.activeLocation];
    document.querySelector('.workshop-name').style.display = servObj.activeLocation !== 'village' ? 'block' : 'none';
    document.querySelector('.current-testing-wrap').style.display = servObj.activeLocation !== 'village' ? 'block' : 'none';
}
