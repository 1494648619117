import { goToLocation } from "./goToLocation";

const workshopName = document.querySelector('.workshop-name');
let testingBtn = document.querySelector('.testing');
let backpak = document.querySelector('.backpack-wrap');


export function startGame() {
    
    servObj.gameIsStarted = true;
    goToLocation('village');

    document.querySelector('.preview-block').style.display = 'none';
    workshopName.style.display = 'block';
    testingBtn && (testingBtn.style.display = 'block');
    backpak && (backpak.style.display = 'block');

}
