import * as THREE from 'three';
import * as Camera from '../Camera.js';
import { setVisibility } from '../visibility_recursive.js';
import { setSelectable } from '../select_object.js';
import { animations } from '../animations.js';
import { materials } from '../materials.js';

const carpentryHoverable = [
];
const weavingLeatherHoverable = [
];
const windmillHoverable = [
];
const forgeHoverable = [
    'forge_gorn',
    'forge_molot',
    'forge_kleshi',
    'forge_nakovalnya',
];
const militaryCampHoverable = [
];
const interiorHoverable = {
    'carpentry_workshop': carpentryHoverable,
    'weaving_leather_workshop': weavingLeatherHoverable,
    'hand_windmill': windmillHoverable,
    'forge': forgeHoverable,
    'military_camp': militaryCampHoverable
}

const interiorCameras = {
    'carpentry_workshop': undefined,
    'weaving_leather_workshop': undefined,
    'hand_windmill': undefined,
    'forge': undefined,
    'military_camp': undefined
}

let scene;

export function initInteriors(canvas){
    scene = statesManager.states.villageScene.value;
    
    Object.keys(interiorCameras).forEach(interiorName => setUpCamera(canvas, interiorName));
    
    materials.setAnimatedOverlay({ obj: scene.getObjectByName('forge_pyl_anim'), videoElemID: 'dust-video', playbackRate: .7, maxAppearOpacity: .8, fadePercentage: .1 });
    materials.setAnimatedOverlay({ obj: scene.getObjectByName('forge_dym_anim'), videoElemID: 'smoke-video', playbackRate: 1, maxAppearOpacity: .6, fadePercentage: .1 });

    animations.randomGlow({ 
        obj: scene.getObjectByName('forge_ugli'),
        minIntensity: .5,
        maxIntensity: 1.5,
        minDuration: 0.9,
        maxDuration: 1.3, });
}

export function goInside(interiorName){
    statesManager.states.sceneObjectContainers.value.forEach(obj => setVisibility(obj, false));
    const interiorParent = scene.getObjectByName(interiorName + '_interior_pivot')
    setVisibility(interiorParent, true);

    statesManager.setState('activeCamera', interiorCameras[interiorName]);

    setSelectable(interiorHoverable[statesManager.states.reachedLocation.value]);

    animations.fadeIn();
}

function setUpCamera(canvas, interiorName) {

    const importedCamera = scene.getObjectByName(interiorName + '_camera');
    const bg = scene.getObjectByName(interiorName + '_fon');

    const interiorCamera = interiorCameras[interiorName] = new Camera.Perspective(canvas, importedCamera.fov * .85);
    interiorCamera.viewportFit.type = Camera.ViewportFitFill;

    interiorCamera.controls.setLookAt(importedCamera.position.x, importedCamera.position.y, importedCamera.position.z, bg.position.x, bg.position.y, bg.position.z);
    interiorCamera.controls.polarRotateSpeed = 0;
    interiorCamera.controls.azimuthRotateSpeed = 0;
    interiorCamera.controls.dollySpeed = 0;
    interiorCamera.controls.truckSpeed = 0.01;
    
    interiorCamera.setTruckOnPointerMove(true, {x: .1, y: .1});

    // Camera bounds
    // const camBoundaryBoxGeo = new THREE.BoxGeometry( .15, .15, .15 ); 
    // const camBoundaryBoxMesh = new THREE.Mesh( camBoundaryBoxGeo ); 
    // camBoundaryBoxMesh.position.copy(importedCamera.position);
    // camBoundaryBoxMesh.rotation.copy(importedCamera.rotation);
    // const camBoundaryBox3 = new THREE.Box3().setFromObject(camBoundaryBoxMesh);
    // interiorCamera.controls.setBoundary(camBoundaryBox3);
    // interiorCamera.controls.boundaryEnclosesCamera = true;
}