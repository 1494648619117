import { Vector2, Raycaster } from 'three';

const raycaster = new Raycaster();

const pointer = new Vector2();

const objects = [
    'instanced_grid',
    'military_camp',
    'forge',
    'hand_windmill',
    'carpentry_workshop',
    'weaving_leather_workshop',
    
    'forge_fon',
    'forge_gorn',
    'forge_molot',
    'forge_kleshi',
    'forge_nakovalnya',
];

export function initRaycaster(scene){
    objects.forEach( (oName, i) => objects[i] = scene.getObjectByName(oName) );

    container3D.addEventListener('pointermove', onPointerMove );
    container3D.addEventListener('click', onClick );
}

function onPointerMove(event){
    pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
    pointer.y = - (event.clientY / window.innerHeight) * 2 + 1;

    const intersects = getIntersects();
    if(!intersects){
        statesManager.setState('hovered3D', []);
        return;
    } 
    statesManager.setState('hovered3D', intersects);
}

function onClick(){
    const intersects = getIntersects();
    if(!intersects) return;

    statesManager.setState('clicked3D', intersects);
}

function getIntersects(){
    const camera = statesManager.states.activeCamera.value;
    raycaster.setFromCamera( pointer, camera );
    const intersects = raycaster.intersectObjects( objects );
    return intersects.length > 0 && intersects;
}

