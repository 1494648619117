import * as THREE from 'three';
import { onResize } from './resize.js';
import { loadMainScene, goToVillage, updateVillage } from './scenes/village.js';
import { initRaycaster } from './raycaster.js';
import { annotations } from './annotations.js';
import { initSelectObject } from './select_object.js';
import { animations } from './animations.js';

//Initial states
statesManager.setState('villageScene', undefined);
statesManager.setState('villageLoaded', false);
statesManager.setState('hovered3D', []);
statesManager.setState('clicked3D', []);
statesManager.setState('reachedLocation', 'village', false);

const container3D = document.getElementById("d3d-container");
window.container3D = container3D;
const foreground = document.getElementById("foreground");
window.container3D.foreground = foreground;
container3D.foreground.style.opacity = 0;


const renderer = new THREE.WebGLRenderer({ 
    antialias: true,
    toneMapping: THREE.ACESFilmicToneMapping,
    outputColorSpace: THREE.SRGBColorSpace,
});
const canvas = renderer.domElement;
container3D.appendChild(canvas);
const container3DWidth = container3D.offsetWidth;
const container3DHeight = container3D.offsetHeight;
renderer.setSize(container3DWidth, container3DHeight);
statesManager.setState('rendererCanvas', canvas);

let scene, activeCamera;

statesManager.setState('activeCamera', undefined, false, [setActiveCamera]);

loadMainScene(canvas, (newScene) => {
    scene = newScene;

    statesManager.setState('villageLoaded', true);
    
    goToVillage();
    
    statesManager.setState('reachedLocation', statesManager.states.reachedLocation.value, true);

    animations.initHandler(scene);

    initRaycaster(scene);

    annotations.init(scene);

    initSelectObject();

    window.addEventListener('resize', () => onResize(renderer, activeCamera, container3D));

    animate();

});

const clock = new THREE.Clock();

function animate() {
    const deltaTime = clock.getDelta();

    requestAnimationFrame(animate);
    
    updateVillage(deltaTime);

    annotations.update(activeCamera);
    
    activeCamera.update(deltaTime);

    animations.update(deltaTime);

    renderer.render(scene, activeCamera);

}

function setActiveCamera(changes){
    activeCamera = changes.newValue;
    if (activeCamera) onResize(renderer, activeCamera, container3D);
}