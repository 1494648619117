import * as THREE from 'three';
import * as Camera from '../Camera.js';
import { loader } from '../loader.js';
import grid from '../grid.js';
import { gsap } from "gsap";
import { setVisibility } from '../visibility_recursive.js';
import { initPathfindingGrid, findPath, createInstancedGrid, clearInstancedColor } from '../pathfinding.js';
import { initInteriors } from './interior.js';
import { goInside } from './interior.js';
import { animations } from '../animations.js';
import { materials } from '../materials.js';

const scene = new THREE.Scene();

let villageCamera;
let player;

let movingToLocation = undefined;

const cameraForwardOffset = 7;
const defaultZoom = 1.4;

let moveTimeline = gsap.timeline();
let colorTimeline = gsap.timeline();

let sceneObjectContainers;
const sceneObjectContainerNames = [
    'main_scene_pivot',
    'instanced_grid',
    'grid',
    'carpentry_workshop_interior_pivot',
    'weaving_leather_workshop_interior_pivot',
    'hand_windmill_interior_pivot',
    'forge_interior_pivot',
    'military_camp_interior_pivot',
];

let villageObjects;
const villageObjectsNames = [
    'main_scene_pivot',
    'instanced_grid',
    'grid',
];
const clickableObjectNames = [
    'instanced_grid',
    'carpentry_workshop',
    'weaving_leather_workshop',
    'hand_windmill',
    'forge',
    'military_camp',
]

const texturesToLoad = {
    village: [
        'village_test_house.jpg',
        'village_test_house_2.jpg',
        'village_test_house_3.jpg',
        'village_test_player.jpg'
    ],
    carpentry_workshop: [],
    weaving_leather_workshop: [],
    hand_windmill: [],
    forge: [
        'forge_balka.webp',
        'forge_bochka.webp',
        'forge_dym.webp',
        'forge_dym_2.webp',
        'forge_dym_3.webp',
        'forge_fon.webp',
        'forge_gorn.webp',
        'forge_kadushka.webp',
        'forge_kleshi.webp',
        'forge_klinki.webp',
        'forge_molot.webp',
        'forge_nakovalnya.webp',
        'forge_stol_1.webp',
        'forge_stol_2.webp',
        'forge_ugli.webp',
        'forge_valun.webp',
        'forge_vedro.webp',
    ],
    military_camp: [],
};

const locationLoadStatus = {
    village: 'not-loaded',
    forge: 'not-loaded',
    carpentry_workshop: 'not-loaded',
    weaving_leather_workshop: 'not-loaded',
    hand_windmill: 'not-loaded',
    military_camp: 'not-loaded',
};

let loadScene = new Event('load_scene',{bubbles: true});

export function loadLocationTextures(locationName, promise, onLoad){

    locationLoadStatus[locationName] = 'loading';

    loader.loadTextures('./textures/' + locationName + '_textures', texturesToLoad[locationName], async (textures) => {

        locationLoadStatus[locationName] = 'loaded';

        if (onLoad) onLoad();
        
        if (promise) await promise;
        
        textures.forEach(texture => {
            let material = materials.list.find(mat => mat.name === texture.name.split('.')[0]);
            if (!material) return;
            material.map = texture;
            material.needsUpdate = true; 
        });
        console.log('test: ', servObj.activeLocation, locationName);
        
        if(servObj.activeLocation == locationName) document.body.dispatchEvent(loadScene);
    });
}

export function loadMainScene(canvas, onLoad) {
    let sceneLoadPromise = new Promise(function(resolve, reject) {
        loader.loadGLTF('./3d/holmgard.glb', (gltf) => { 
            scene.add(gltf.scene);

            resolve();

            statesManager.setState('villageScene', scene);
            
            initVillage(canvas);
            initInteriors(canvas);

            const getObject = (name) => scene.getObjectByName(name);
            villageObjects = villageObjectsNames.map(getObject);
            sceneObjectContainers = sceneObjectContainerNames.map(getObject);

            statesManager.setState('sceneObjectContainers', sceneObjectContainers);

            statesManager.states.reachedLocation.callbacks.push((changes) => {

                if(changes.newValue == 'village' && changes.prevValue != 'village') {
                    animations.fadeOut({ onComplete: goToVillage });
                    return;
                }

                if(changes.newValue == 'village') return;

                goInside(changes.newValue);
            });

            if (onLoad) onLoad(scene);
            
        });
    });

    const loadInteriors = () => {
        Object.keys(locationLoadStatus).forEach((key) => {
            if (key === 'village') return;
            loadLocationTextures(key, sceneLoadPromise);
        });
    }
    loadLocationTextures('village', sceneLoadPromise, loadInteriors);

}

export function goToVillage() {
    sceneObjectContainers.forEach(obj => setVisibility(obj, false));
    villageObjects.forEach(obj => setVisibility(obj, true));

    villageCamera.controls.moveTo(player.position.x + cameraForwardOffset, player.position.y, player.position.z, false);
    villageCamera.controls.zoomTo(defaultZoom);
    
    statesManager.setState('activeCamera', villageCamera);
    
    animations.fadeIn();
}

export function updateVillage(deltaTime) {

    if (movingToLocation) villageCamera.controls.moveTo( player.position.x + cameraForwardOffset, player.position.y, player.position.z, true );

}

function initVillage(canvas) {

    setUpCamera(canvas);

    const gridDivisions = 100;
    const cellSize = 2;

    const instancedGrid = setUpGrid(gridDivisions, cellSize);
    
    setUpPlayer();

    statesManager.states.clicked3D.callbacks.push( (changes) => { 
        moveToClicked(changes, gridDivisions, instancedGrid) 
    });

    statesManager.states.hovered3D.callbacks.push( (changes) => {
        // highlightHoveredCell(changes);
    });
}

function setUpCamera(canvas) {
    let importedCamera = scene.getObjectByName('village_camera');
    villageCamera = new Camera.Orthographic(canvas);
    villageCamera.copy(importedCamera, true);
    villageCamera.rotation.set(0, 0, 0);
    villageCamera.frustumSize = villageCamera.right * 2;
    
    villageCamera.controls.setPosition(importedCamera.position.x, importedCamera.position.y, importedCamera.position.z);
    villageCamera.controls.rotateTo(-0.8003789701639575, 0.9541803125538296);
    villageCamera.controls.verticalDragToForward = true;
    villageCamera.controls.polarRotateSpeed = .2;
    villageCamera.controls.azimuthRotateSpeed = .2;
    villageCamera.controls.minAzimuthAngle = -.85;
    villageCamera.controls.maxAzimuthAngle = -.75;
    villageCamera.controls.minPolarAngle = .9;
    villageCamera.controls.maxPolarAngle = 1.0;
    villageCamera.controls.minZoom = .3;
    villageCamera.controls.maxZoom = 2;
    villageCamera.controls.zoomTo(1.5);

    villageCamera.setRotateOnPointerMove(true);
}

function setUpGrid(gridDivisions, cellSize) {
    grid(scene, gridDivisions, cellSize, 'grid');
    
    const obstaclesArray = [];
    const obstacleContainer = scene.getObjectByName('obstacle_container');
    setVisibility(obstacleContainer, false);
    obstacleContainer.children.forEach( (mesh) => {
        const box = new THREE.Box3();
        mesh.geometry.computeBoundingBox();
        box.copy( mesh.geometry.boundingBox ).applyMatrix4( mesh.matrixWorld );
        obstaclesArray.push(box);
    });

    initPathfindingGrid(gridDivisions, cellSize, obstaclesArray);
    
    const instancedGrid = createInstancedGrid(cellSize, 'instanced_grid');
    scene.add(instancedGrid);
    
    return instancedGrid;
}

function setUpPlayer(){
    player = scene.getObjectByName('player_pivot');
    let searchParams = new URLSearchParams(window.location.search);
    let href = window.location.href;
    let origin = window.location.origin;
    let pathname = window.location.pathname;
    let rootHref = href.includes('testing') ? href.slice(0, href.indexOf('test')) : 
                    href.includes('thing') ? href.slice(0, href.indexOf('thing')):
                    origin + pathname;
    let needSetCoord = searchParams.get('needSetCoord');
    
    needSetCoord && window.history.pushState(null,null,rootHref);
    let currentCoord = localStorage.getItem('currentCoord')?.split(',').map(el=>Number(el)) || [];
    
    player.currentNode = (needSetCoord && currentCoord.length == 2) ?  currentCoord : [40, 46]; //[16, 50];
    localStorage.setItem('currentCoord', '');
    
    player.position.copy(findPath(player.currentNode[0], player.currentNode[1], 0, 0).positions[0]);
    
    const applyNodePosition = (changes) => {
        player.currentNode = changes.newValue;
        player.position.copy(findPath(player.currentNode[0], player.currentNode[1], 0, 0).positions[0]);
    }
    statesManager.setState('playerCurrentNode', player.currentNode, true, [ applyNodePosition ]);

    villageCamera.controls.moveTo( player.position.x + cameraForwardOffset, player.position.y, player.position.z, false );
}

function createPath(changes, gridDivisions){
    movingToLocation = undefined;

    const intersects = changes.newValue;
    
    let x = Math.trunc(intersects[0].instanceId / gridDivisions);
    let y = intersects[0].instanceId % gridDivisions;
    
    const locationNodes = {
        instanced_grid: [x, y],
        military_camp: [49, 39],
        forge: [40, 46],
        hand_windmill: [34, 36],
        carpentry_workshop: [35, 47],
        weaving_leather_workshop: [27, 45],
    }
    movingToLocation = intersects[0].object.name;
    
    if (!locationNodes[movingToLocation]) return;

    x = locationNodes[movingToLocation][0];
    y = locationNodes[movingToLocation][1];

    const path = findPath(player.currentNode[0], player.currentNode[1], x, y)
    path.nodes.shift();
    path.positions.shift();

    return path;
}

function resetTimelines(instancedGrid){
    moveTimeline.kill();
    moveTimeline = gsap.timeline({ 
        onComplete: () => {
            statesManager.setState('playerCurrentNode', player.currentNode);
            
            if (movingToLocation != 'instanced_grid') {
                const locMesh = scene.getObjectByName(movingToLocation);

                const onComplete = () => {
                    statesManager.setState('reachedLocation', movingToLocation);
                    movingToLocation = undefined;
                }
                animations.goInside({ controls: villageCamera.controls, locMesh: locMesh, onComplete: onComplete });

                return;
            }

            movingToLocation = undefined;
        },
    });

    colorTimeline.kill();
    colorTimeline = gsap.timeline({ 
        delay: .5, 
        onComplete: () => clearInstancedColor(instancedGrid),
        onInterrupt: () => clearInstancedColor(instancedGrid),
    });
}

function animateMoving(gridDivisions, instancedGrid, path){
    path.positions.forEach( (pos, i) => {
        //Player moving animation
        moveTimeline.to(player.position, {
            x: pos.x, 
            z: pos.z, 
            duration: .25,
            ease: "none", 
            onComplete: () => {
                player.currentNode = path.nodes[i];
                localStorage.setItem('currentCoord', player.currentNode);
            },
        });
        
        //Instanced cells color animation
        const matrixIndex =  path.nodes[i][0] * gridDivisions + path.nodes[i][1];
        const color = {
            from: '#00FF00',
            to: '#000000'
        }
        instancedGrid.setColorAt(matrixIndex, new THREE.Color(color.from))
        instancedGrid.instanceColor.needsUpdate = true;
        colorTimeline.to(color, {
            from: color.to,
            duration: .25,
            ease: "none",
            onUpdate: () => {
                instancedGrid.setColorAt(matrixIndex, new THREE.Color(color.from))
                instancedGrid.instanceColor.needsUpdate = true;
            },
        });
    });
}

function moveToClicked(changes, gridDivisions, instancedGrid){

    const clickedObjName = changes.newValue[0].object.name;
    if (!clickableObjectNames.includes(clickedObjName)) return;
    
    const path = createPath(changes, gridDivisions);
    
    resetTimelines(instancedGrid);
    
    animateMoving(gridDivisions, instancedGrid, path);

}

function highlightHoveredCell(changes){
    const intersects = changes.newValue;
    if (intersects[0].object !== instancedGrid) return;

    clearInstancedColor(instancedGrid);

    const matrixIndex = intersects[0].instanceId;
    instancedGrid.setColorAt(matrixIndex, new THREE.Color('#00FF00'))
    instancedGrid.instanceColor.needsUpdate = true;
}